<template>
  <div class="tw-flex tw-justify-center">
    <div
      v-if="message.Channel === 'main'"
      class="log-message-main tw-text-sm tw-text-gray-400 tw-py-4 tw-w-2/3"
    >
      <div v-if="message.Type === 'AgentAssigned'" class="">
        Chat session is currently transferred to agent:
        <strong> {{ message.AgentName }}</strong>
        {{ message.CreatedAt | format }}
      </div>
      <div v-if="message.Type === 'RoomReopened'" class="">
        This chat room has been
        <strong>reopened</strong>.
        {{ message.CreatedAt | format }}
      </div>
      <div v-if="message.Type === 'RoomClosed'" class="">
        This chat room has been
        <strong>closed</strong>.
        {{ message.CreatedAt | format }}
      </div>
      <div v-if="message.Type === 'RoomCreated'" class="">
        This chat room has been
        <strong>created</strong>.
        {{ message.CreatedAt | format }}
      </div>
    </div>
    <div
      v-else-if="message.Channel === 'background'"
      class="log-message-main tw-text-sm tw-text-gray-400 tw-py-4 tw-w-2/3"
    >
      <div v-if="message.Type === 'AgentAssigned'" class="">
        This chat room has a new agent:
        <strong> {{ message.AgentName }}</strong>
        {{ message.CreatedAt | format }}
      </div>
      <div v-if="message.Type === 'RoomCreated'" class="">
        This chat room has been
        <strong>created</strong>.
        {{ message.CreatedAt | format }}
      </div>
    </div>
    <div
      v-else
      class="log-message-main tw-text-sm tw-text-gray-400 tw-py-4 tw-w-2/3"
    >
      <div v-if="message.Type === 'RoomReopened'" class="">
        This chat room has been
        <strong>reopened</strong>.
        {{ message.CreatedAt | format }}
      </div>
      <div v-if="message.Type === 'RoomClosed'" class="">
        This chat room has been
        <strong>closed</strong>.
        {{ message.CreatedAt | format }}
      </div>
      <div v-if="message.Type === 'RoomCreated'" class="">
        This chat room has been
        <strong>created</strong>.
        {{ message.CreatedAt | format }}
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  name: "LogMessage",
  props: {
    message: Object,
  },
  computed: {
    logText() {
      if (this.message.Type === "AgentAssigned")
        return `Chat session is currently transferred to agent: ${this.message.AgentName}`;
      return this.message.Type;
    },
  },
  filters: {
    format(value) {
      return dayjs(value).format("HH:mm DD/MM/YYYY");
    },
  },
};
</script>

<style>
</style>